.App {
  text-align: left;
  min-height: 89vh;
  
}

.Body {


  min-height: 100vh;
  margin: 0;
  background-color:  #f3f3f3;
  color: black;
  max-width: 100vw ;
  
}


.pageContent {
  text-align: center;

}


.pageContentLeft {
  text-align: left;
  font-size: 16px;
}

 

.pageContentSmall {
  text-align: center;
  font-size: 14px;
  margin-left: 5%;
  margin-right: 5%; 
}

.pageContentSmallLeft {
  text-align: left;
  font-size: 14px;
  
  
}


.pageContentSmall2 {
  text-align: left;
  font-size: 14px;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0%;
}

.pageContentMed {
  text-align: center;
  font-size: 23px;
}

.pageContentMed2 {
  text-align: center;
  font-size: 23px;
  color: white;

}




.App-header {
  background-color: #d12121;
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vh);
  color: white;
}

.App-link {
  color: #a8530e;
}

.App-link-button {
  
  background-color: #9c7800;
  color: #ffffff;
  border: none;
  padding-left: 35px;
  padding-right: 35px;
}


.emailLink {
  
  top :0;
  margin: 10px;
  font-size: 18px;
  color: white;
}


.navBarLink {
  position : sticky;
  top :0;
  margin: 10px;
  font-size: 24px;
  color: white;
}

.navBarLinkActive {
  position : sticky;
  top :0;
  margin: 10px;
  font-size: 24px;
  color: #fcef42;
 
}


.navBarTitle {
  margin: 5vmin;
  font-size: calc(26px + 2vh);
  position : sticky;
  top :0;
  color: white;
  
}

.navBarBox {
  margin: 0px;
  font-size: calc(26px + 2vh);
  position : sticky;
  top :0;
  color: rgb(0, 0, 0);
  background-color:  #01003f;
}

.footBox {
  margin: 0px;
  font-size: calc(4px + 1vh);
  text-align: center;
  min-height: 11vh;
  color: rgb(172, 172, 172);
  background-color:  #2b2b2b;
  bottom: 0;
 }


.divBox {
  margin: 0px;
  font-size: 3px;
  text-align: center;
  bottom : 0;
  color: rgb(0, 0, 0);
  background-color:  #2b2b2b; 
  
}

.divBox2 {
  margin: 0px;
  font-size: 3px;
  text-align: center;
  bottom : 0;
  color: rgb(0, 0, 0);
  background-color: inherit; 
  
}




.list {
text-align: left;
list-style-position: inside;
margin-left: 1%;
margin-right: 1%;
}



.App-logo {
  height: 40px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
